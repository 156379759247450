<template>
    <v-container>
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <DlgRedemptionStatus ref="dlgRedemptionStatus" :visible="dlgRedemptionStatusVisible" @close="dlgRedemptionStatusClose"></DlgRedemptionStatus>
        <div class="mb-3">
          <v-toolbar>
            <v-btn icon class="hidden-xs-only" @click="$router.back()"><v-icon>mdi-arrow-left</v-icon></v-btn>
            <v-toolbar-title>Redemption Detail</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div> 
        <v-row class="mt-3">
           <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark color="panel1">
                        <v-toolbar-title>Redemption Info</v-toolbar-title>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field v-for="(info,index) in  rdDetailField" :key="'custIndex'+index" outlined v-model="rdDetail[info.fieldName]" :label="info.name" :readonly="info.readOnly"></v-text-field>
                            </v-col>
                        </v-row>

                    </v-container>
                
                </v-card>

                <v-card>
                    <v-toolbar dark color="panel2">
                        <v-toolbar-title>Delivery Address</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="updateDAddress"><v-icon>save</v-icon></v-btn>
                    </v-toolbar>
                    <v-container class="pt-2 mt-3">
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field outlined v-model="rdDetail['address1']" label="Address 1"></v-text-field>
                                <v-text-field outlined v-model="rdDetail['address2']" label="Address 2"></v-text-field>
                                <v-text-field outlined v-model="rdDetail['address3']" label="Address 3"></v-text-field>
                                <v-text-field outlined v-model="rdDetail['city']" label="City"></v-text-field>
                                <v-select
                                    label="State"
                                    outlined
                                    dense
                                    :items="stateList"
                                    item-text="cs_name"
                                    item-value="cs_code"
                                    v-model="rdDetail['state']"
                                ></v-select>                                
                                <v-text-field outlined v-model="rdDetail['postal_code']" label="Postal Code"></v-text-field>

                            </v-col>
                        </v-row>

                    </v-container>
                
                </v-card>

                <v-toolbar  dark class="primary mt-5"><v-toolbar-title>Item List</v-toolbar-title>
                </v-toolbar>

                    <v-container class="my-5">
                        <v-card  class="mt-5" v-for="(rec,index) in rdItemList" :key="'index-'+index">
                            <v-row class="pl-3" v-for="(rec2,index2) in rdItemField"  :key="'index-'+index2">
                                <v-col cols="12" sm="6" md="6">
                                    {{rec2.name}}
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    {{ rec[rec2.fieldName]}}
                                </v-col>
                            </v-row>
                            <v-row class="pl-3">
                                <v-col cols="12" sm="6" md="6">
                                    Status
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-chip dark :color="vchipColor[rec.rd_status].color" >{{ rec.rd_status}}</v-chip>                                    
                                    <v-btn icon @click="showDlgRedemptionStatus(index)"><v-icon>mdi-pencil</v-icon></v-btn>
                                </v-col>
                            </v-row>
                        </v-card>

                    </v-container>                  
            </v-col>
            <!--image-->
            <v-col cols="12" md="12">
                <v-card>
                    <v-toolbar dark color="panel3">
                        <v-toolbar-title>Tracking Time Line</v-toolbar-title>
                    </v-toolbar>

                    <v-container class="pt-2 mt-3">
                        <v-card  class="mt-5" v-for="(rec,index) in rdItemList" :key="'index-'+index">
                            <v-card-title>{{rec.consignment}}</v-card-title>
                            <v-timeline align-top dense v-if="rec.tracking_timeline">
                                <template v-for="(timeLine,index2) in rec.tracking_timeline">
                                <v-timeline-item  :key="'index' + index2" small>
                                    <v-row class="pt-1">
                                        <v-col cols="12" sm="4" md="4">
                                            <div class="text-caption">Tracking Date</div>
                                            <div class="text-caption">{{ timeLine[0] }}</div>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4">
                                            <div class="text-caption">Time</div>
                                            <div class="text-caption">{{ timeLine[2] }}</div>
                                        </v-col>

                                        <v-col cols="12" sm="4" md="4">
                                        
                                        <strong>{{ timeLine[1] }}</strong>
                                        <div class="text-caption">
                                            {{ timeLine[3] }}
                                        </div>
                                        </v-col>
                                    </v-row>
                                </v-timeline-item>     
                            </template>               
                            </v-timeline>
                            <v-timeline align-top dense v-else>
                                <template v-for="(timeLine,index2) in rdStatusList[rec.redemption_item_id]">
                                <v-timeline-item  :key="'index' + index2" small>
                                    <v-row class="pt-1">
                                        <v-col cols="12" sm="4" md="4">
                                        <div class="text-caption">System Date</div>                                   
                                        <div class="text-caption">{{ timeLine['created_date'] }}</div>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="4">
                                        <div class="text-caption">Status Date</div>                                            
                                        <div class="text-caption">{{ timeLine['status_date'].substring(0,10) }}</div>
                                        </v-col>

                                        <v-col cols="12" sm="4" md="4">
                                        <strong>{{ timeLine['status'] }}</strong>
                                        <div class="text-caption">
                                            {{ timeLine['remarks'] }}
                                        </div>
                                        </v-col>
                                    </v-row>
                                </v-timeline-item>     
                            </template>               

                            </v-timeline>
                                        

                        </v-card>
                    </v-container>
                </v-card>
            </v-col>

        </v-row>

    </v-container>  
</template>
    
<script>
import DlgMessage from '@/components/DlgMessage'
import DlgRedemptionStatus from '@/components/DlgRedemptionStatus'
import ApRedemptionService from "@/services/ApRedemptionService"
import ApCustomerService from "@/services/ApCustomerService"
export default {
  components:{DlgMessage,DlgRedemptionStatus},
  props: {
       caller: String
    },
  data() {
    return{
            //breadcrumb
            items: [
              {text:'Transaction',disabled: false,href:'/',},
              {text:'Detail',disabled: false,href:'#',},
              ],
            statusList:[],
            form:[],
            rdDetail:[],
            rdItemList:[],
            rdStatusList:[],
            rdDetailField:[
                {name:"Process Reference No.",fieldName:"process_reference_no",readOnly:true},
                {name:"Redemption Date",fieldName:"redemption_date",readOnly:true},
                {name:"Full Name",fieldName:"full_name",readOnly:true},
                {name:"Cell Phone No.",fieldName:"cell_phone",readOnly:true},
                {name:"Remarks",fieldName:"remarks",readOnly:true},
                {name:"Total Points",fieldName:"total_points",readOnly:true},
                {name:"Total Item",fieldName:"total_item",readOnly:true},
                {name:"Total Qty",fieldName:"total_quantity",readOnly:true},
            ],
            rdItemField:[
                {name:"Code:",fieldName:"ci_code"},
                {name:"Name:",fieldName:"ci_name"},
                {name:"Quantity:",fieldName:"quantity"},
                {name:"Pts Total:",fieldName:"rd_pts_total"},
                {name:"Consignment #:",fieldName:"consignment"},
            ],            
            routeParam:[],
            rules: {
                required: value => !!value || 'Required.',
                email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || 'Invalid Email address', 
                integer: value => /^[0-9]+$/.test(value) || 'Number only',
                decimal: value => /^\d{0,9}(\.\d{1,2})?$/.test(value) || 'Number with 2 decimal points',
                pinNumber: value => value.length == 6 || 'require 6 digits number',
                searchWord: value => value.length >= 3 || 'require 3 or more letters',
            },
            stateList:[],
            vchipColor:{
                "NEW":{"color":"orange"},
                "IN PROGRESS":{"color":"lime"},
                "SENT":{"color":"light-green"},
                "DELIVERED":{"color":"green"},
                "CANCELLED":{"color":"red"},
                "RETURNED":{"color":"red"},
                "REFUND":{"color":"red"},
            },
            dlgRedemptionStatusVisible:false,

    }
  },
  async created() {
        this.routeParam['process_reference_no'] = this.$route.params.process_reference_no;
        this.routeParam['redemption_id'] = this.$route.params.redemption_id;
        this.viewDetail();
        this.getParameter();
  },
  
  methods:{

    async viewDetail(){
        let request = {
            process_reference_no:this.routeParam['process_reference_no'],
            redemption_id:this.routeParam['redemption_id']}
        try{
            ApRedemptionService.view_redemption(request).then((res) => {
                this.rdDetail = res.data.rd;
                for (const [key, value] of Object.entries(this.rdDetail)) {
                    if(value == null){
                        this.rdDetail[key] = "";
                    }
                }                
                this.rdItemList = res.data.rdItem;
                this.rdStatusList = res.data.rdStatus;


            }).catch((e)=>{
                console.log(e.response);
                this.$refs.dlgMsg.showDlgBox(true,'error','View Redemption',e.response);
            })
            ;
        } catch(e) {
            console.log(e);
            //this.$refs.dlgMsg.showDlgBox(true,'error','Supplier','Unknown Error');
        }

    },
    dlgClose(){
        this.visible = false;        
    },

    async getParameter(){
            ApCustomerService.address_param().then((res) => {
                this.stateList = res.data.stateList;
                //console.log(res.data.stateList)
            }).catch((e)=>{
                if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','View Redemption',e.response);
            })
            ;
    },

    async updateDAddress(){
        this.form = this.rdDetail;
        this.form['login_id'] = "";
        try{
            ApRedemptionService.update_daddress(this.form).then(() => {
                this.$refs.dlgMsg.showDlgBox(true,'success','View Redemption','Success.');
            }).catch((e)=>{
                if(e.response.data.error_message)
                    this.$refs.dlgMsg.showDlgBox(true,'error','View Redemption',e.response.data.error_message);
                else
                    this.$refs.dlgMsg.showDlgBox(true,'error','View Redemption',e);
            })
            ;
        } catch(e) {
            console.log(e);
            if(e)
                this.$refs.dlgMsg.showDlgBox(true,'error','View Redemption','Unknown Error');
        }
    },
    showDlgRedemptionStatus(index){
        if(index>-1){
            let redemption_item_id = parseInt(this.rdItemList[index].redemption_item_id);
            // let rd_status = this.rdItemList[index].rd_status
            let rdStatusDetail = this.rdStatusList[redemption_item_id.toString()][0];
            
            this.$refs.dlgRedemptionStatus.dlgShow(redemption_item_id,rdStatusDetail);
            this.dlgRedemptionStatusVisible = true;
        }


    },
    dlgRedemptionStatusClose(){
        this.dlgRedemptionStatusVisible = false;
        this.viewDetail();
    },

  }
}
</script>